/*jslint passfail: true*/
/*global $, fontsLoaded, console, counter*/
var resize_timer, init, gallery, checkFonts, slider_main, slider_obj, wykladowcy_modal, smooth_scroll, top_button, parallax, count, loginform;

init = function () {
    "use strict";
    $('a.smooth').smoothScroll({
        afterScroll: function() {
            
        },
        afterScroll: function() {

        },
        offset: -100
    });
    
    var waypoints = [];
    waypoints.push(new Waypoint({
        element: $('#kontakt'),
        handler: function(direction) {
            $('ul.nav li.active').removeClass('active');
            if(direction == 'down') {
                $('ul.nav li:nth(2)').addClass('active');
            }
            if(direction == 'up') {
                $('ul.nav li:nth(1)').addClass('active');
            }
        },
        offset: '75%'
    }));
    waypoints.push(new Waypoint({
        element: $('#uslugi'),
        handler: function(direction) {
            $('ul.nav li.active').removeClass('active');
            if(direction == 'down') {
                $('ul.nav li:nth(1)').addClass('active');
            }
            if(direction == 'up') {
                $('ul.nav li:nth(0)').addClass('active');
            }
        },
        offset: '75%'
    }));
//    $('a.smooth').on('click', function(e) {
//        console.log($('#'+$(e.target).closest('a').attr('href').replace('#', '')));
//        $.smoothScroll({
//            offset: '-110px',
//
//            // one of 'top' or 'left'
//            direction: 'top',
//
//            // only use if you want to override default behavior
//            scrollTarget: $('#'+$(e.target).closest('a').attr('href').replace('#', '')),
//
//            // fn(opts) function to be called before scrolling occurs.
//            // `this` is the element(s) being scrolled
//            beforeScroll: function() {},
//
//            // fn(opts) function to be called after scrolling occurs.
//            // `this` is the triggering element
//            afterScroll: function() {},
//            easing: 'swing',
//
//            // speed can be a number or 'auto'
//            // if 'auto', the speed will be calculated based on the formula:
//            // (current scroll position - target scroll position) / autoCoeffic
//            speed: 400,
//
//            // autoCoefficent: Only used when speed set to "auto".
//            // The higher this number, the faster the scroll speed
//            autoCoefficient: 2,
//
//            // $.fn.smoothScroll only: whether to prevent the default click action
//            preventDefault: true
//        });
//        return false;
//    });
};

$(function () {
    "use strict";
    checkFonts();
});

checkFonts = function () {
    "use strict";
    if (!fontsLoaded) {
        setTimeout(function () { checkFonts(); }, 50);
        return;
    }
    init();
};